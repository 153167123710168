
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import I18n from '@/i18n/index';
import closeHeaderMenu from '@/_ns_modules/src/ts/closeHeaderMenu';
import { Linkset, Link } from '@/repository/kontent/stores/sitemapStore';
import HeaderItem from './HeaderItem.vue';

@Component({
  components: {
    HeaderItem,
  },
})
export default class HeaderGroup extends Vue {
  @Prop(Object) linkgroup!: Linkset | Link;
  @Prop(String) title!: string;
  @Prop(Boolean) wideMenu!: boolean;
  private i18n = I18n;

  private closeHeaderMenu() {
    const mql = window.matchMedia('screen and (max-width: 835px)');
    if (!mql.matches) closeHeaderMenu();
  }

  private get toplinkItem() {
    return this.linkgroup?.toplink?.value[0];
  }
  private isLinkset() {
    if (!this.linkgroup) return;
    return 'toplink' in this.linkgroup;
  }
  private get linkList() {
    if (!this.isLinkset()) return [];
    const linkset = this.linkgroup as Linkset;
    const links = linkset.list?.value;
    if (!links) return [];
    const result = links
      .map((x) => {
        if (x.toplink?.value[0]?.system.type === 'anchor') {
          return x.list?.value;
        } else {
          return x;
        }
      })
      .flat();
    return [...Array.from(new Set(result))];
  }
  private get topLinkRouteName() {
    return this.toplinkItem?.routername.value;
  }
  private get externalUrl() {
    return this.linkgroup?.url?.value;
  }
}
