import utillty from './utility';

export default class Disclosure {
    private root: Element;
    private trigger: HTMLElement;
    private panel: HTMLElement;
    private panelInner: HTMLElement;
    private panelID: string;
    private isTransition: boolean;
    private mql: MediaQueryList;

    constructor(
        root: Element,
        trigger: HTMLElement,
        panel: HTMLElement,
        panelInner: HTMLElement,
        panelID: string
    ) {
        this.root = root;
        this.trigger = trigger;
        this.panel = panel;
        this.panelInner = panelInner;
        this.panelID = `disclosure-${panelID}`;
        this.isTransition = false;
        this.mql = utillty.mql;

        // set attribute
        this.panel.id = this.panelID;
        this.trigger.setAttribute('aria-controls', this.panelID);
        this.trigger.setAttribute('aria-expanded', 'false');
        this.trigger.setAttribute('role', 'button');

        // set mql
        this.mql.addListener(this.mqlHandler);
        this.mqlHandler();

        // set event
        this.trigger.addEventListener('click', this.clickHandler as EventListener);
        this.trigger.addEventListener('keydown', this.keydownHandler as EventListener);
        this.panel.addEventListener('transitionend', this.transitionendHandler);
    }

    /*
     *
     * mqlHandler
     *
     * - SP幅になった時、パネルのheightを0（ディスクロージャーが閉じた状態）
     * - PC幅になった時、パネルのstyle属性でのheight指定を削除
     * - open用classを外して、aria-expandedをfalseに切り替える。（ディスクロージャーが開く前の状態）
     *
     */
    private mqlHandler = (): void => {
        if (this.mql.matches) {
            this.panel.style.height = '0px';
        } else {
            this.panel.style.height = '';
        }

        this.root.classList.remove(utillty.className.open);
        this.trigger.setAttribute('aria-expanded', 'false');
    }

    /*
     *
     * clickHandler
     *
     * - SP幅かつパネルのトランジションが起こっていない時にフラグを有効にして判定処理
     * - open用classの有無で開閉を判定
     *
     */
    private clickHandler = (e: MouseEvent): void => {
        // PC幅の時return
        if (!this.mql.matches) {
            return;
        }

        e.preventDefault();

        // トランジション中はreturn
        if (this.isTransition) {
            return;
        }

        this.isTransition = true;

        if (this.root.classList.contains(utillty.className.open)) {
            this.panelClose();
        } else {
            this.panelOpen();
        }
    }

    /*
     *
     * keydownHandler
     *
     * - Enterキーの時開閉処理を実行
     *
     */
    private keydownHandler = (e: KeyboardEvent): void => {
        // PC幅の時return
        if (!this.mql.matches) {
            return;
        }

        // Enterキー以外の時 or トランジション中はreturn
        if (
            e.key !== 'Enter' ||
            this.isTransition
        ) {
            return;
        }

        e.preventDefault();
        this.isTransition = true;

        if (this.root.classList.contains(utillty.className.open)) {
            this.panelClose();
        } else {
            this.panelOpen();
        }
    }

    /*
     *
     * transitionendHandler
     *
     * - SP幅でパネルのトランジションがheightで起こった時、かつボタンのaria-expandedがtrueの時（パネルが開いた後の状態）パネルのheightをautoに変更
     * - 上記以外の時open用classを外す。（閉じた後の状態）
     *
     */
    private transitionendHandler = (e: TransitionEvent): void => {
        if (
            this.mql.matches &&
            e.propertyName === 'height' &&
            this.trigger.getAttribute('aria-expanded') === 'true'
        ) {
            this.panel.style.height = 'auto';
        } else {
            this.root.classList.remove(utillty.className.open);
        }

        this.isTransition = false;
    }

    /*
     *
     * panelOpen
     *
     * - root要素にopenClassを付与
     * - パネルのheightを0pxからコンテンツの高さに変更
     * - aria-expandedをtrueに変更
     *
     */
    private panelOpen(): void {
        this.root.classList.add(utillty.className.open);

        this.panel.style.height = '0px';
        setTimeout(() => {
            this.panel.style.height = `${this.panelInner.clientHeight}px`;
            this.trigger.setAttribute('aria-expanded', 'true');
        }, 100);
    }

    /*
     *
     * panelClose
     *
     * - パネルのheightをコンテンツの高さから0pxに変更
     * - aria-expandedをfalseに変更
     *
     */
    private panelClose(): void {
        this.panel.style.height = `${this.panelInner.clientHeight}px`;
        setTimeout(() => {
            this.panel.style.height = '0px';
            this.trigger.setAttribute('aria-expanded', 'false');
        }, 100);
    }
}
