import utility from './utility';

export default (): void => {
    window.addEventListener('DOMContentLoaded', () => {
        const banner = document.querySelector('.gdprBanner') as HTMLElement;
        const gdprBtn = banner.querySelector('.gdprBanner__btn button') as HTMLButtonElement;

        if (banner && gdprBtn) {
            // ページロード時にgdpr部分にフォーカス移動
            banner.tabIndex = 0;
            banner.focus();
            banner.removeAttribute('tabindex');

            gdprBtn.addEventListener('click', () => {
                banner.classList.add(utility.className.hidden);
            });
        }
    });
};
