import Swiper, {SwiperOptions} from 'swiper';
import utility from './utility';

export default (rootSelector: string, nextBtnSelector: string, prevBtnSelector: string, options: SwiperOptions, fadeFlag = false): void => {
    class ControlCarousel {
        private carousel: Swiper | null;
        private carouselFlag: boolean;
        private fadeFlag: boolean;
        private swiperOptions: SwiperOptions;
        private observer: IntersectionObserver;
        private rootSelector: string;
        private checkElem: HTMLElement | null;
        private nextBtn: HTMLElement | null;
        private prevBtn: HTMLElement | null;

        constructor() {
            this.carousel = null;
            this.carouselFlag = false;
            this.fadeFlag = fadeFlag;
            this.swiperOptions = options;

            this.observer = new IntersectionObserver(this.observerCallback, {
                root: null,
                rootMargin: '0px',
                threshold: 0
            });

            this.rootSelector = rootSelector;
            this.checkElem = document.querySelector(rootSelector);
            this.nextBtn = document.querySelector(nextBtnSelector);
            this.prevBtn = document.querySelector(prevBtnSelector);

            if (this.checkElem) {
                this.observer.observe(this.checkElem);
            }
        }

        // 表示パネルだけフォーカス操作ができるようにする。
        private setTabIndex = (): void => {
            if (this.carousel === null) {
                return;
            }

            const panelLength = this.carousel.slides.length;

            if (panelLength > 0) {
                for (let i = 0; i < panelLength; i++) {
                    const slide = this.carousel.slides[i];

                    if (slide.classList.contains(utility.className.show)) {
                        slide.children[0].tabIndex = '0';
                    } else {
                        slide.children[0].tabIndex = '-1';
                    }
                }
            }
        };

        // PC時カルーセル表示 ⇔ SP時カルーセル解除の切り替え
        private checkCarousel = (): void => {
            if (
                utility.mql.matches &&
                this.carouselFlag &&
                this.carousel !== null
            ) {
                const panelLength = this.carousel.slides.length;

                if (panelLength > 0) {
                    // インスタンスを削除する前に、パネルのtabindexとswiperが付与するclassを外す
                    for (let i = 0; i < panelLength; i++) {
                        const slide = this.carousel.slides[i];

                        slide.children[0].removeAttribute('tabindex');
                        slide.classList.remove('swiper-slide-duplicate-next');
                        slide.classList.remove('swiper-slide-duplicate-prev');
                    }

                    this.carousel.destroy(false, true);
                    this.carousel = null;
                    this.carouselFlag = false;
                }

                // TOPカルーセルの時、SP時表示用に再生成された要素にis-showを付与する。
                if (this.fadeFlag) {
                    this.showTopCarousel();
                }
            } else if (
                !utility.mql.matches &&
                !this.carouselFlag
            ) {
                this.carousel = new Swiper(this.rootSelector, this.swiperOptions);
                this.carousel.on('transitionEnd', this.setTabIndex);
                this.setTabIndex();
                this.carouselFlag = true;
            }
        };

        // PC時カルーセル部分が画面に入った時インスタンス操作の判定とイベント付与を実行
        private observerCallback = (entries: IntersectionObserverEntry[], observerObj: IntersectionObserver): void => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                if (entry.isIntersecting) {
                    utility.mql.addListener(this.checkCarousel);

                    this.checkCarousel();

                    // ボタンイベント付与（デザインの関係上ボタンをカルーセル部分の外に設置）
                    if (
                        this.nextBtn &&
                        this.prevBtn
                    ) {
                        this.nextBtn.addEventListener('click', () => {
                            if (this.carousel !== null) {
                                this.carousel.slideNext();
                            }
                        });

                        this.prevBtn.addEventListener('click', () => {
                            if (this.carousel !== null) {
                                this.carousel.slidePrev();
                            }
                        });
                    }

                    observerObj.unobserve(entry.target);
                }
            });
        };

        // PCとSPで切り替わる際のTOPカルーセルフェードイン表示操作
        private showTopCarousel = (): void => {
            const panels = document.querySelectorAll('.topCasestudyArea__panel.is-fadeIn');
            const panelsLength = panels.length;

            if (panelsLength > 0) {
                for (let i = 0; i < panelsLength; i++) {
                    panels[i].classList.add(utility.className.show);
                }
            }
        }
    }

    new ControlCarousel(); // eslint-disable-line
};
