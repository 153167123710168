
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class History extends ContentItem {
    public date!: Elements.DateTimeElement;
    public thumbnail!: Elements.AssetsElement;
    public title!: Elements.TextElement;
    public seoMetadataExampleToIncludeInAnyTypeMetaDescription!: Elements.TextElement;
    public description!: Elements.TextElement;
    public seoMetadataExampleToIncludeInAnyTypeMetaTitle!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'seo_metadata_example_to_include_in_any_type__meta_description') {
                    return 'seoMetadataExampleToIncludeInAnyTypeMetaDescription';
                }
                if (elementName === 'seo_metadata_example_to_include_in_any_type__meta_title') {
                    return 'seoMetadataExampleToIncludeInAnyTypeMetaTitle';
                }
                return elementName;
            })
        });
    }
}
