import { Client } from "./client";
import { ContentItem, MultipleItemQuery, SortOrder, Pagination } from '@kentico/kontent-delivery';
import I18n, {DEFAULT_LANGUAGE} from '@/i18n/index';

export type SystemAttributes
  = 'system.id'
  | 'system.name'
  | 'system.codename'
  | 'system.type'
  | 'system.last_modified'
  | 'system.language'

export type SortOption = {
  key: SystemAttributes;
  order: SortOrder;
}

export default class Store<T extends ContentItem> {
  protected _query: MultipleItemQuery<T>;
  protected _list = [] as Array<T>;
  protected _targetId = "";
  protected _targetName = "";
  protected _sortOption: SortOption | null = null;
  protected _type = "";
  protected _pagination: Pagination | null = null;

  constructor(type: string) {
    this._type = type;
    this._query = Client.items<T>().type(this._type)
    if (I18n.language != DEFAULT_LANGUAGE){
      this._query = this._query.languageParameter(I18n.language)
    }
  }

  protected store = async () => {
    if (this._list?.length) return;
    const result = await this._query.toPromise();
    this._list = result.items;
    this._pagination = result.pagination;
  }

  protected get _i18nedList() {
    return this._list.filter(x => x.system.language === I18n.language);
  }

  public restore = async () => {
    const result = await this._query.toPromise();
    this._list = result.items;
    this._pagination = result.pagination;
  }

  public sortWithSystemParameter = async (sortOption: SortOption) => {
    await this.store()
    this._sortOption = sortOption;
    this._query = this._query.orderParameter(sortOption.key, sortOption.order);

    this._list.sort(this.compareWithSystemParameter)
  }
  private compareWithSystemParameter(x: T, y: T) {
    if (!this._sortOption) return 0;
    if (x[this._sortOption.key] == y[this._sortOption.key]) return 0;

    let a = x[this._sortOption.key] < y[this._sortOption.key];
    if (this._sortOption.order == SortOrder.desc) a = !a;
    return a ? -1 : 1;
  }
  public setOrderParameter(key:string, order:SortOrder){
    this._query = this._query.orderParameter(key, order);
  }

  public setElementsParameter(paramArr:string[]){
    this._query = this._query.elementsParameter(paramArr)
  }

  public setFilter(key:string, value:string){
    this._query = this._query.equalsFilter(key, value);
  }

  public setRangeFilter(key:string, min:string|null, max:string|null, lessThanOrEqualFlag?:boolean){
    if(min)this._query = this._query.greaterThanOrEqualFilter(key, min);
    if(max)this._query = lessThanOrEqualFlag ? this._query.lessThanFilter(key, max) : this._query.lessThanOrEqualFilter(key, max);
  }

  public setLimitSkip(limit:number|null, skip:number|null){
    if(limit)this._query = this._query.limitParameter(limit)
    if(skip)this._query = this._query.skipParameter(skip)
  }

  public setDepth(value:number){
    this._query = this._query.depthParameter(value)
  }

  public setDirectUrl(url:string){
    this._query.withUrl(url);
  }

  public get rawList() {
    return this._list
  }

  public get list() {
    return this._i18nedList
  }

  public get item() {
    return this._i18nedList.filter(x => x.system.id === this._targetId || x.system.name === this._targetName).find(x => x);
  }

  public set targetId(id: string) {
    this._targetName = ''
    this._targetId = id
  }

  public set targetName(name: string) {
    this._targetId = ''
    this._targetName = name
  }

  public async getAll() {
    await this.store()
    return this._i18nedList;
  }

  public async get(id: string) {
    await this.store()
    return this._i18nedList.find(x => x.system.id === id);
  }

  public async getWithName(name: string) {
    await this.store()
    return this._i18nedList.find(x => x.system.name === name);
  }

  public async fetchAll(): Promise<Array<T>> {
    const x = await this._query.languageParameter(I18n.language).toPromise();
    return x.items ?? [] as Array<T>;
  }

  public async fetchWithId(id: string): Promise<T> {
    const x = await this._query.equalsFilter('system.id', id).languageParameter(I18n.language).toPromise();
    return x.firstItem ?? {} as T;
  }

  public async fetchWithName(name: string): Promise<T> {
    const x = await this._query.equalsFilter('system.name', name).languageParameter(I18n.language).toPromise();
    return x.firstItem ?? {} as T;
  }

  public initQuery(){
    this._query = Client.items<T>().type(this._type);
    if (I18n.language != DEFAULT_LANGUAGE){
      this._query = this._query.languageParameter(I18n.language)
    }
  }
}
