


















import { Component, Vue } from 'vue-property-decorator';
import gdprBanner from '@/_mitsue-modules/src/ts/module/gdprBanner';

@Component
export default class GdprBanner extends Vue {
  private clicked = false;

  private async mounted() {
    gdprBanner();
    this.reset();
  }

  private async onClickOk() {
    localStorage.setItem('clicked', 'true');
    this.reset();
  }

  private async reset() {
    this.clicked = localStorage.getItem('clicked')?.toLowerCase() === 'true';
  }
}
