// header & localNav currrent

import utillty from './utility';

export default (): void => {
    const createCurrent = (links: NodeList): void => {
        let currentLink: HTMLAnchorElement;
        let targetPath = '/';
        const pathArray = location.pathname.split('/');
        const linkLength = links.length;

        pathArray.forEach(path => {
            if (
                !(/.html/.test(path)) &&
                path.length > 0
            ) {
                targetPath += `${path}/`;
            }
        });

        // check current link
        for (let i = 0; i < linkLength; i++) {
            const linkPath = (links[i] as HTMLAnchorElement).getAttribute('href');

            if (targetPath === linkPath) {
                currentLink = (links[i] as HTMLAnchorElement);
                currentLink.classList.add(utillty.className.current);
                currentLink.removeAttribute('href');
                break;
            }
        }
    };

    // local nav current
    const localNav = document.querySelector('.localNav');

    if (!localNav) {
        return;
    }

    const localNavLinks = localNav.querySelectorAll('a');

    if (localNavLinks.length > 0) {
        createCurrent(localNavLinks);
    }
};
