import Store from './base/store';
import { Sitemap } from "../models/_sitemap";
import { Linkset } from "../models/_linkset";
import { Link } from "../models/_link"

class SitemapStore extends Store<Sitemap>{
  constructor(){
    super('sitemap')
  }
  public getLinkSetList(){
    return this.list[0]?.link.value
  }
  public getLinkSet(key:string){
    const linksetList = this.getLinkSetList()
    if (!linksetList) return new Linkset()
    return this.getLinkSetList()
      .find((x) => x.system.name == key) as Linkset
  }
}

class LinksetStore extends Store<Linkset>{
  constructor(){
    super('_linkset')
  }
}

export { SitemapStore, LinksetStore, Sitemap, Linkset, Link };
