import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

export default (): void => {
    class PageScroll {
        private trigger: HTMLAnchorElement;
        private linkHref: string;
        private target: HTMLElement | null;
        private header: HTMLElement;
        private topPos: number;
        private time: number;
        private scrollFlag: boolean;

        constructor(trigger: HTMLAnchorElement) {
            this.trigger = trigger;
            this.linkHref = this.trigger.hash;
            this.target = (this.linkHref === '#top') ? document.documentElement : document.querySelector(this.linkHref);
            this.header = document.querySelector('.header') as HTMLElement;
            this.topPos = 0;
            this.time = 0;
            this.scrollFlag = false;

            if (this.target) {
                window.addEventListener('scroll', this.windowScrollHandler);
                this.trigger.addEventListener('click', this.triggerClickHandler);
            }
        }

        // アンカーリンクclick時に、フラグを有効にしてターゲットの位置を取得してスクロール
        private triggerClickHandler = (e: MouseEvent): void => {
            e.preventDefault();

            this.scrollFlag = true;

            const targetPos = (this.target as Element).getBoundingClientRect().top;

            this.topPos = (this.linkHref === '#top') ? 0 : targetPos + window.pageYOffset - this.header.clientHeight;

            window.scrollTo({
                top: this.topPos,
                behavior: 'smooth'
            });
        }

        // window.scrollToの後にfocus＆locaion処理を実行
        private windowScrollHandler = (): void => {
            // スクロール機能でスクロールしてない時（this.scrollFlagがfalseの時）はreturn
            if (!this.scrollFlag) {
                return;
            }

            clearTimeout(this.time);

            this.time = setTimeout(() => {
                if (!this.target) {
                    return;
                }

                this.target.tabIndex = 0;
                this.target.focus();
                this.target.removeAttribute('tabindex');
                history.pushState(null, '', this.linkHref);
                this.scrollFlag = false;
            }, 300);
        }
    }

    const links = document.querySelectorAll('a[href^="#"]');
    const linksLength = links.length;

    if (linksLength > 0) {
        for (let i = 0; i < linksLength; i++) {
            const trigger = links[i] as HTMLAnchorElement;

            new PageScroll(trigger); //eslint-disable-line
        }
    }
};
