
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Service extends ContentItem {
    public thumbnail!: Elements.AssetsElement;
    public externalUrl!: Elements.TextElement;
    public shorttitle!: Elements.TextElement;
    public translationTranslatonSettings!: Elements.CustomElement;
    public subtitle!: Elements.TextElement;
    public body!: Elements.RichTextElement;
    public title!: Elements.TextElement;
    public seoMetadataExampleToIncludeInAnyTypeMetaDescription!: Elements.TextElement;
    public routername!: Elements.TextElement;
    public summary!: Elements.TextElement;
    public seoMetadataExampleToIncludeInAnyTypeMetaTitle!: Elements.TextElement;
    public html!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'external_url') {
                    return 'externalUrl';
                }
                if (elementName === 'translation__translaton_settings') {
                    return 'translationTranslatonSettings';
                }
                if (elementName === 'seo_metadata_example_to_include_in_any_type__meta_description') {
                    return 'seoMetadataExampleToIncludeInAnyTypeMetaDescription';
                }
                if (elementName === 'seo_metadata_example_to_include_in_any_type__meta_title') {
                    return 'seoMetadataExampleToIncludeInAnyTypeMetaTitle';
                }
                return elementName;
            })
        });
    }
}
