










import { Component, Vue, Watch } from 'vue-property-decorator';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import GdprBanner from './components/GdprBanner.vue';

import i18n from '@/i18n/index';
import callMitsueModules from '@/_ns_modules/src/ts/callMitsueModules';
import { reactive, triggerRef } from '@vue/composition-api';
import { SitemapStore } from '@/repository/kontent/stores/sitemapStore';

import { Route } from 'vue-router';

@Component({
  components: {
    Header,
    Footer,
    GdprBanner,
  },
})
export default class App extends Vue {
  private corporateEn = 'NEXTSCAPE Inc.';
  private corporateJa = document.title;
  private i18n = i18n;
  private created() {
    this.sitemapData.setDepth(3);
    this.sitemapData.restore();
    this.$i18n.locale = i18n.language;
  }

  @Watch('i18n.language')
  onTextChanged(newLang: string): void {
    this.sitemapData = reactive(new SitemapStore());
    this.sitemapData.setDepth(3);
    this.sitemapData.restore();
    this.$i18n.locale = newLang;
  }

  mounted() {
    this.$router.beforeEach((to: Route, from: Route, next) => {
      this.setMeta(to);
      next();
    });
  }

  private companyName = '';
  private companyDesc = '';
  private sitemapData = reactive(new SitemapStore());

  private async mouted() {
    callMitsueModules();
    var to = this.$route;
    this.setMeta(to);
  }

  private setMeta = (to: Route) => {
    this.companyName = this.$i18n.locale == 'en' ? this.corporateEn : this.corporateJa;
    this.companyDesc =
      this.companyDesc || document.querySelector("meta[name='description']")?.getAttribute('content') || '';

    const metaObj = Array.isArray(to.meta)
      ? to.meta.filter((x) => x.language === i18n.language.toString())[0]
      : to.meta;

    // site name
    document.querySelector("meta[property='og:site_name']")?.setAttribute('content', this.companyName);

    // title
    let titleVal = this.companyName;
    if (metaObj?.title) {
      titleVal = metaObj.title;
    }
    document.title = titleVal;
    document.querySelector("meta[property='og:title']")?.setAttribute('content', titleVal);

    // description
    let descVal = this.companyDesc;
    if (metaObj?.desc) {
      descVal = metaObj.desc;
    }
    document.querySelector("meta[name='description']")?.setAttribute('content', descVal);
    document.querySelector("meta[property='og:description']")?.setAttribute('content', descVal);

    // og:url
    document.querySelector("meta[property='og:url']")?.setAttribute('content', location.href);
    // og:type トップかそうでないかで判別
    const ogType = to.name == 'Home' ? 'website' : 'article';
    document.querySelector("meta[property='og:type']")?.setAttribute('content', ogType);
  };
}
