















































import { Component, Prop, Vue } from 'vue-property-decorator';
import I18n from '@/i18n/index';
import { Service } from '@/repository/kontent/stores/serviceStore';
import { Link } from '@/repository/kontent/stores/sitemapStore';

@Component
export default class FooterItem extends Vue {
  @Prop(Object) linkItem!: Link | Service;
  @Prop(String) top!: string;
  private i18n = I18n;
  private get routerName() {
    return this.linkItem?.routername?.value;
  }
  private get externalUrl() {
    return this.linkItem?.externalUrl?.value;
  }
  private get title() {
    return this.linkItem?.shorttitle?.value ?? this.linkItem?.title?.value;
  }
}
