export default (): void => {
    const links = document.querySelectorAll('a[target="_blank"]');
    const rootLength = links.length;
    const label = document.documentElement.lang === 'ja' ? '別窓で開く' : 'open in new window';

    if (rootLength > 0) {
        for (let i = 0; i < rootLength; i++) {
            const svg = `<svg class="iconBlank" xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" aria-labelledby="addIcon-${i}"><title id="addIcon-${i}">${label}</title><g transform="translate(0.5)"><g transform="translate(2)" fill="none" stroke-width="1"><rect width="9" height="7" stroke="none"/><rect x="0.5" y="0.5" width="8" height="6" fill="none"/></g><path d="M627.76,6335.545h-2v6h8v-2" transform="translate(-625.76 -6332.545)" fill="none" stroke-width="1"/></g></svg>`;

            const iconWrapper = links[i].querySelector('.iconBlankWrapper');

            if (iconWrapper) {
                iconWrapper.innerHTML += svg;

                continue;
            }

            // リンク直下にインナーとかがあるかを判定（インナーがある時はインナーの中にsvgを追加）
            if (links[i].children.length === 0) {
                links[i].innerHTML += svg;
            } else {
                links[i].children[0].innerHTML += svg;
            }
        }
    }
};
