import dropDown from '@/_mitsue-modules/src/ts/module/dropDown';
import addIcon from '@/_mitsue-modules/src/ts/module/addIcon';
import currentLink from '@/_mitsue-modules/src/ts/module/currentLink';
import runCommonDisclosure from '@/_mitsue-modules/src/ts/module/runCommonDisclosure';
import setPageTop from '@/_mitsue-modules/src/ts/module/setPageTop';
import hamburgerMenu from '@/_mitsue-modules/src/ts/module/hamburgerMenu';
import pageScroll from '@/_mitsue-modules/src/ts/module/pageScroll';
import utility from '@/_mitsue-modules/src/ts/module/utility';
import gdprBanner from '@/_mitsue-modules/src/ts/module/gdprBanner';
// import addFadeInContents from '@/_mitsue-modules/src/ts/module/addFadeInContents';
import controlCarousel from '@/_mitsue-modules/src/ts/module/controlCarousel';
import tabList from '@/_mitsue-modules/src/ts/module/tabList';

export default () => {
  dropDown();
  addIcon();
  currentLink();
  runCommonDisclosure();
  setPageTop();
  hamburgerMenu();
  pageScroll();
  gdprBanner();
  // addFadeInContents();
  controlCarousel('.carousel__contents', '.carousel__nextBtn', '.carousel__prevBtn', {
    loop: true,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slidesPerView: 3,
    wrapperClass: 'carousel__contentsInner',
    slideClass: 'carousel__panel',
    slideActiveClass: utility.className.current,
    slideVisibleClass: utility.className.show,

    pagination: {
      el: '.carousel__pagination',
      clickable: true,
      bulletActiveClass: utility.className.current,
      bulletClass: 'carousel__paginationItem',
    },

    a11y: {
      notificationClass: 'carousel__notification',
    },
  });
  controlCarousel(
    '.topCasestudyArea__contents',
    '.topCasestudyArea__nextBtn',
    '.topCasestudyArea__prevBtn',
    {
      loop: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      slidesPerView: 3,
      wrapperClass: 'topCasestudyArea__contentsInner',
      slideClass: 'topCasestudyArea__panel',
      slideActiveClass: utility.className.current,
      slideVisibleClass: utility.className.show,

      pagination: {
        el: '.topCasestudyArea__pagination',
        clickable: true,
        bulletActiveClass: utility.className.current,
        bulletClass: 'topCasestudyArea__paginationItem',
      },

      a11y: {
        notificationClass: 'topCasestudyArea__notification',
      },
    },
    true,
  );
  tabList();
};
