
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Newsitem extends ContentItem {
    public title!: Elements.TextElement;
    public translationTranslatonSettings!: Elements.CustomElement;
    public date!: Elements.DateTimeElement;
    public body!: Elements.RichTextElement;
    public summary!: Elements.TextElement;
    public seoMetadataExampleToIncludeInAnyTypeMetaDescription!: Elements.TextElement;
    public thumbnail!: Elements.AssetsElement;
    public newsGroup!: Elements.TaxonomyElement;
    public seoMetadataExampleToIncludeInAnyTypeMetaTitle!: Elements.TextElement;
    public htmlbody!: Elements.TextElement;
    public url!: Elements.UrlSlugElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'translation__translaton_settings') {
                    return 'translationTranslatonSettings';
                }
                if (elementName === 'seo_metadata_example_to_include_in_any_type__meta_description') {
                    return 'seoMetadataExampleToIncludeInAnyTypeMetaDescription';
                }
                if (elementName === 'news_group') {
                    return 'newsGroup';
                }
                if (elementName === 'seo_metadata_example_to_include_in_any_type__meta_title') {
                    return 'seoMetadataExampleToIncludeInAnyTypeMetaTitle';
                }
                return elementName;
            })
        });
    }
}
