import Vue from 'vue';
import * as ja from './localization/ja-JP.json';
import * as en from './localization/en-US.json';
// FIXME import { reactive } from '@vue/composition-api';
import { replaceLanguageCode } from '@/router/index';

export type Languages = 'ja' | 'en';
export const DEFAULT_LANGUAGE: Languages = 'ja';

class I18n {
  private _language: Languages = DEFAULT_LANGUAGE;

  public async setLanguage(l: Languages) {
    if (this._language === l) return;

    this._language = l;
    replaceLanguageCode(l);
    switch (l) {
      case 'ja':
        document.documentElement.lang = 'ja';
        break;
      default:
        document.documentElement.lang = 'en';
        break;
    }
  }

  public get language() {
    return this._language;
  }
}

export default Vue.observable(new I18n());
// FIXME Vue3系で修正されるはず export default reactive(new I18n());
