


















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Disclosure from '@/_mitsue-modules/src/ts/module/disclosure';
import setPageTop from '@/_mitsue-modules/src/ts/module/setPageTop';
import I18n from '@/i18n/index';
import FooterGroup from './FooterGroup.vue';
import { Sitemap } from '@/repository/kontent/models/_sitemap';

@Component({
  components: {
    FooterGroup,
  },
})
export default class Footer extends Vue {
  @Prop(Object) footerData!: Sitemap;
  private i18n = I18n;
  private footerListJa = ['企業情報', 'サービス', 'ニュース', ['採用情報', 'ポリシー']];
  private footerListEn = ['企業情報', 'サービス', 'ニュース', 'ポリシー'];
  private mounted() {
    setPageTop();

    const roots = document.querySelectorAll('.footer__siteLinkItem');
    const rootsLength = roots.length;

    if (rootsLength === 0) {
      return;
    }

    for (let i = 0; i < rootsLength; i++) {
      if (!roots[i]) {
        return;
      }
      const trigger = roots[i].querySelector('.footer__siteLinkHdg span') as HTMLElement;
      const panel = roots[i].querySelector('.footer__siteLinkContents') as HTMLElement;
      const panelInner = roots[i].querySelector('.footer__siteLinkList') as HTMLElement;

      if (trigger && panel && panelInner) {
        new Disclosure(roots[i], trigger, panel, panelInner, `footer${i}`); //eslint-disable-line
      }
    }
  }
}
