


















































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import I18n from '@/i18n/index';
import hamburgerMenu from '@/_mitsue-modules/src/ts/module/hamburgerMenu';
import Disclosure from '@/_mitsue-modules/src/ts/module/disclosure';
import dropDown from '@/_mitsue-modules/src/ts/module/dropDown';
import pageScroll from '@/_mitsue-modules/src/ts/module/pageScroll';
import HeaderGroup from './HeaderGroup.vue';
import { Sitemap } from '@/repository/kontent/models/_sitemap';

@Component({
  components: {
    HeaderGroup,
  },
})
export default class Header extends Vue {
  @Prop(Object) headerData!: Sitemap;
  private i18n = I18n;
  private headerList = ['企業情報', 'サービス', 'ニュース', '採用情報', 'Blog'];
  private isMouseDown = false;
  private windowWidth = 0;
  private wasNewDisclosure = false;

  @Watch('headerData.list')
  onTextChanged(): void {
    dropDown();
    this.addEvent();
  }

  private async mounted() {
    hamburgerMenu();
    this.windowWidth = window.innerWidth;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    pageScroll();
  }

  private async onClickMenu() {
    if (window.innerWidth < 845) {
      document
        .querySelectorAll('.header__itemPanel')
        .forEach((x) => ((x as HTMLElement).style.opacity = '1'));
    }
  }

  private async addEvent() {
    const triggers = document.querySelectorAll('.header__itemLink:not([target="_blank"])');
    if (triggers.length === 0) return;
    if (this.wasNewDisclosure) return;

    for (let i = 0; i < triggers.length; i++) {
      const trigger = triggers[i] as HTMLElement;
      const root = trigger.parentElement;
      if (!root) continue;

      const panel = root.querySelector('.header__itemPanel') as HTMLElement;
      const panelInner = root.querySelector('.header__panelInner') as HTMLElement;

      if (panel && panelInner) {
        new Disclosure(root, trigger, panel, panelInner, `header${i}`); //eslint-disable-line
      }
    }
    // headerData.listが変わるたびにnewされるとtriggerのidも都度変わってしまい、SP表示で日本語切り替えした場合にid違いで操作できなくなるので
    this.wasNewDisclosure = true;
  }

  private mouseUp() {
    this.isMouseDown = false;
  }
  private mouseDown() {
    this.isMouseDown = true;
  }
}
