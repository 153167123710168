// Kentico Kontent
import { DeliveryClient, TypeResolver } from "@kentico/kontent-delivery";
import { Company } from '../../models/company';
import { Generictext } from '../../models/generictext';
import { History } from '../../models/history';
import { Home } from '../../models/home';
import { Leadership } from '../../models/leadership';
import { Newsitem } from '../../models/newsitem';
import { Servicegroup } from '../../models/servicegroup';
import { Service } from '../../models/service';
import { Solutionindex } from '../../models/solutionindex';

const projectId = process.env.VUE_APP_KONTENT_PROJECT_ID || "";
const previewApiKey = process.env.VUE_APP_PREVIEW_API_KEY || "";

const Client = new DeliveryClient({
  projectId,
  previewApiKey,
  globalQueryConfig: {
    usePreviewMode: !!previewApiKey, // previewApiKeyが設定されている場合、プレビューモードをON。
  },
  typeResolvers: [
    new TypeResolver('company', (rawData) => new Company),
    new TypeResolver('generictext', (rawData) => new Generictext),
    new TypeResolver('history', (rawData) => new History),
    new TypeResolver('home', (rawData) => new Home),
    new TypeResolver('leadership', (rawData) => new Leadership),
    new TypeResolver('newsitem', (rawData) => new Newsitem),
    new TypeResolver('servicegroup', (rawData) => new Servicegroup),
    new TypeResolver('service', (rawData) => new Service),
    new TypeResolver('solutionindex', (rawData) => new Solutionindex),
  ]
});

export { Client };
