
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Linkset extends ContentItem {
    public title!: Elements.TextElement;
    public toplink!: Elements.LinkedItemsElement<ContentItem>;
    public description!: Elements.TextElement;
    public list!: Elements.LinkedItemsElement<ContentItem>;
}
