import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import App from './App.vue';
import router from './router';
import fadeIn from '@/_ns_modules/src/ts/fadeInDirective';
import focusOut from '@/_ns_modules/src/ts/focusOutDirectivue';
import VueScrollTo from 'vue-scrollto';
import VueI18n from 'vue-i18n';

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);
Vue.use(fadeIn);
Vue.use(focusOut);
Vue.use(VueScrollTo);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'ja',
  messages: {
    en: {},
    ja: {},
  },
});

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
