import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=70acbdfe&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"
import style0 from "./Header.vue?vue&type=style&index=0&id=70acbdfe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70acbdfe",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/localization/toppage.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Csrc%5Ccomponents%5CHeader.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./Header.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports