import Disclosure from './disclosure';

export default (): void => {
    // header SP Disclosure
    ((): void => {
        const triggers = document.querySelectorAll('.header__itemLink:not([target="_blank"])');
        const triggersLength = triggers.length;

        if (triggersLength === 0) {
            return;
        }

        for (let i = 0; i < triggersLength; i++) {
            const trigger = triggers[i] as HTMLElement;
            const root = trigger.parentElement;

            if (!root) {
                return;
            }

            const panel = root.querySelector('.header__itemPanel') as HTMLElement;
            const panelInner = root.querySelector('.header__panelInner') as HTMLElement;

            if (
                panel &&
                panelInner
            ) {
                new Disclosure(root, trigger, panel, panelInner, `header${i}`); //eslint-disable-line
            }
        }
    })();

    // footer SP Disclosure
    ((): void => {
        const roots = document.querySelectorAll('.footer__siteLinkItem');
        const rootsLength = roots.length;

        if (rootsLength === 0) {
            return;
        }

        for (let i = 0; i < rootsLength; i++) {
            if (!roots[i]) {
                return;
            }
            const trigger = roots[i].querySelector('.footer__siteLinkHdg span') as HTMLElement;
            const panel = roots[i].querySelector('.footer__siteLinkContents') as HTMLElement;
            const panelInner = roots[i].querySelector('.footer__siteLinkList') as HTMLElement;

            if (
                trigger &&
                panel &&
                panelInner
            ) {
                new Disclosure(roots[i], trigger, panel, panelInner, `footer${i}`); //eslint-disable-line
            }
        }
    })();
};
