import utility from './utility';

export default (): void => {
    /*
     *
     * ページTOP追従処理
     *
     */
    class FixedPageTop {
        private footer: Element;
        private link: HTMLElement;
        private linkWrapper: HTMLElement;
        private body: HTMLBodyElement;
        private rightPos: number;
        private mql: MediaQueryList;
        private isFixed: boolean;

        constructor(footer: Element) {
            this.footer = footer;
            this.link = footer.querySelector('.footer__pageTop') as HTMLElement;
            this.linkWrapper = this.link.querySelector('p') as HTMLElement;
            this.body = document.body as HTMLBodyElement;
            this.rightPos = ((this.body.clientWidth - this.link.clientWidth) / 2) + 20;
            this.mql = utility.mql;
            this.isFixed = false;

            // set mql
            this.mql.addListener(this.changeRightValue);
            this.changeRightValue();

            // リサイズ時間引き処理後にchangeRightValueを実行
            let timer: number;

            window.addEventListener('resize', () => {
                if (timer) {
                    clearTimeout(timer);
                }

                timer = setTimeout(() => {
                    this.changeRightValue();
                }, 300);
            });

            // 画面をスクロールした際に、footerを基準にしてfixedを切り替える
            const observerCallback = (entries: IntersectionObserverEntry[]): void => {
                entries.forEach((entry: IntersectionObserverEntry) => {
                    this.changeFixed(entry.isIntersecting);
                });
            };

            const observer = new IntersectionObserver(observerCallback, {
                root: null,
                rootMargin: '-30px',
                threshold: 0
            });

            observer.observe(this.footer);
        }

        /*
         * changeRightValue
         * - change rightPos
         * - change linkWrapper style
         */
        private changeRightValue = (): void => {
            if (!this.mql.matches) {
                if (
                    this.isFixed &&
                    this.body.clientWidth > utility.contentWidth
                ) {
                    this.rightPos = ((this.body.clientWidth - this.link.clientWidth) / 2) + 20;
                } else {
                    this.rightPos = 20; // PC時のコンテンツ右マージン
                }
            } else {
                this.rightPos = 15; // SP時のコンテンツ右マージン
            }

            this.linkWrapper.style.right = `${this.rightPos}px`;
        }

        /*
         * changeFixed
         * - toggle fixed class
         * - change fixed flag
         */
        private changeFixed(flag: boolean): void {
            if (flag) {
                this.link.classList.remove(utility.className.fixed);
                this.isFixed = false;
            } else {
                this.link.classList.add(utility.className.fixed);
                this.isFixed = true;
            }

            this.changeRightValue();
        }
    }

    /*
     *
     * ページTOPを表示するか判定する処理
     *
     */
    class ShowPageTop {
        private pageTop: HTMLElement;
        private body: HTMLElement;
        private checkElem: HTMLElement;
        private beforePageScrollValue: number;
        private isScrollUp: boolean;

        constructor(pageTop: HTMLElement) {
            this.pageTop = pageTop;
            this.body = document.body;
            this.beforePageScrollValue = window.pageYOffset;
            this.isScrollUp = false;

            // observer target要素生成&追加
            this.checkElem = document.createElement('div');
            this.checkElem.classList.add('checkPageTop');
            this.body.insertBefore(this.checkElem, this.body.children[0]);

            const observerCallback = (entries: IntersectionObserverEntry[]): void => {
                entries.forEach((entry: IntersectionObserverEntry) => {
                    this.checkShowPageTop(entry.isIntersecting);
                });
            };

            const observer = new IntersectionObserver(observerCallback, {
                root: null,
                rootMargin: '0px',
                threshold: [0, 0.8] // 初回ロードとスクロール後に2回判定する為
            });

            observer.observe(this.checkElem);

            // utility.className.hidden(is-hidden)を付与する際にスクロール方向を条件として使用する。
            window.addEventListener('scroll', () => {
                this.isScrollUp = window.pageYOffset < this.beforePageScrollValue;

                this.beforePageScrollValue = window.pageYOffset;
            });
        }

        private checkShowPageTop(flag: boolean): void {
            if (flag) {
                // ファーストビュー表示
                this.pageTop.classList.remove(utility.className.show);

                // ページ先頭へスクロールしている時だけ非表示にする。
                if ((this.isScrollUp)) {
                    this.pageTop.classList.add(utility.className.hidden);
                }
            } else {
                /*
                 * ページ途中追従
                 */
                this.pageTop.classList.remove(utility.className.hidden);
                this.pageTop.classList.add(utility.className.show);
            }
        }
    }

    const footer = document.querySelector('.footer');

    if (footer) {
        new FixedPageTop(footer); //eslint-disable-line
    }

    const pageTop = document.querySelector('.footer__pageTop') as HTMLElement;

    if (pageTop) {
        new ShowPageTop(pageTop); //eslint-disable-line
    }
};
