
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Company extends ContentItem {
    public officeLocation!: Elements.LinkedItemsElement<ContentItem>;
    public numberOfEmployees!: Elements.TextElement;
    public mvTitle1!: Elements.TextElement;
    public credentials!: Elements.TextElement;
    public capitalStock!: Elements.TextElement;
    public companyName!: Elements.TextElement;
    public date!: Elements.DateTimeElement;
    public seoMetadataExampleToIncludeInAnyTypeMetaDescription!: Elements.TextElement;
    public accessHeadline!: Elements.TextElement;
    public representative!: Elements.LinkedItemsElement<ContentItem>;
    public businessDescription!: Elements.TextElement;
    public mvTitle2!: Elements.TextElement;
    public seoMetadataExampleToIncludeInAnyTypeMetaTitle!: Elements.TextElement;
    public mvSubtitle2!: Elements.TextElement;
    public mvSubtitle1!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'office_location') {
                    return 'officeLocation';
                }
                if (elementName === 'number_of_employees') {
                    return 'numberOfEmployees';
                }
                if (elementName === 'mv_title1') {
                    return 'mvTitle1';
                }
                if (elementName === 'capital_stock') {
                    return 'capitalStock';
                }
                if (elementName === 'company_name') {
                    return 'companyName';
                }
                if (elementName === 'seo_metadata_example_to_include_in_any_type__meta_description') {
                    return 'seoMetadataExampleToIncludeInAnyTypeMetaDescription';
                }
                if (elementName === 'access_headline') {
                    return 'accessHeadline';
                }
                if (elementName === 'business_description') {
                    return 'businessDescription';
                }
                if (elementName === 'mv_title2') {
                    return 'mvTitle2';
                }
                if (elementName === 'seo_metadata_example_to_include_in_any_type__meta_title') {
                    return 'seoMetadataExampleToIncludeInAnyTypeMetaTitle';
                }
                if (elementName === 'mv_subtitle2') {
                    return 'mvSubtitle2';
                }
                if (elementName === 'mv_subtitle1') {
                    return 'mvSubtitle1';
                }
                return elementName;
            })
        });
    }
}
