import utillty from './utility';

export default (): void => {
    class DropDown {
        private root: HTMLElement;
        private items: NodeList;
        private overlay: HTMLElement;
        private links: HTMLElement[];
        private panels: HTMLElement[];
        private openPanelIndex: number | null;
        private checkOpenPanelArray: boolean[];
        private mql: MediaQueryList;
        private userAgent: string;

        constructor(root: HTMLElement) {
            this.root = root;
            this.items = this.root.querySelectorAll('.header__navItem');
            this.overlay = document.querySelector('.header__overlay') as HTMLElement;
            this.links = [];
            this.panels = [];
            this.openPanelIndex = null;
            this.checkOpenPanelArray = [];
            this.mql = utillty.mql;
            this.userAgent = navigator.userAgent;

            const itemsLength = this.items.length;

            if (itemsLength > 0) {
                for (let i = 0; i < itemsLength; i++) {
                    const item = this.items[i] as HTMLElement;

                    this.links[i] = item.querySelector('.header__itemLink') as HTMLElement;
                    this.panels[i] = item.querySelector('.header__itemPanel') as HTMLElement;

                    if (
                        !this.links[i] ||
                        !this.panels[i]
                    ) {
                        continue;
                    }

                    this.panels[i].id = `dropdown-${i}`;
                    this.links[i].setAttribute('aria-controls', this.panels[i].id);
                    this.links[i].setAttribute('aria-expanded', 'false');
                    this.checkOpenPanelArray[i] = false;

                    // 開いているパネルがある時そのパネルを閉じて、マウスの対象になったグロナビカテゴリーリンクに対応するパネルを開く
                    this.links[i].addEventListener('mouseenter', () => {
                        if (
                            this.mql.matches ||
                            this.userAgent.indexOf('iPhone') >= 0 ||
                            this.userAgent.indexOf('iPad') >= 0 ||
                            this.userAgent.indexOf('Android') >= 0
                        ) {
                            return;
                        }

                        if (
                            this.openPanelIndex !== null &&
                            this.openPanelIndex !== i &&
                            this.checkOpenPanelArray[this.openPanelIndex]
                        ) {
                            this.closePanel(this.openPanelIndex);
                        }

                        if (
                            this.openPanelIndex !== i &&
                            !this.checkOpenPanelArray[i]
                        ) {
                            this.openPanel(i);
                        }
                    });

                    // グロナビのリンクかぱねるからマウスが外れたら開いているパネルを閉じる
                    item.addEventListener('mouseleave', () => {
                        if (this.mql.matches) {
                            return;
                        }

                        if (
                            this.openPanelIndex === i &&
                            this.checkOpenPanelArray[this.openPanelIndex]
                        ) {
                            this.closePanel(i);
                        }
                    });

                    // グロナビカテゴリーリンクからshift + tabでフォーカスを1つ前に戻す時、開いているパネルを閉じる
                    this.links[i].addEventListener('keydown', e => {
                        if (this.mql.matches) {
                            return;
                        }

                        if (
                            e.shiftKey &&
                            e.key === 'Tab' &&
                            this.checkOpenPanelArray[i]
                        ) {
                            this.closePanel(i);
                        }
                    });

                    // mouseenterイベントと開閉の判定は同じ
                    this.links[i].addEventListener('focus', () => {
                        if (this.mql.matches) {
                            return;
                        }

                        if (
                            this.openPanelIndex !== null &&
                            this.openPanelIndex !== i &&
                            this.checkOpenPanelArray[this.openPanelIndex]
                        ) {
                            this.closePanel(this.openPanelIndex);
                        }

                        if (
                            this.openPanelIndex !== i &&
                            !this.checkOpenPanelArray[i]
                        ) {
                            this.openPanel(i);
                        }
                    });

                    const panelFocusElems = this.panels[i].querySelectorAll('a');
                    const lastFocusElem = panelFocusElems[panelFocusElems.length - 1];

                    if (lastFocusElem) {
                        lastFocusElem.addEventListener('keydown', e => {
                            if (
                                !e.shiftKey &&
                                e.key === 'Tab'
                            ) {
                                this.closePanel(this.openPanelIndex);
                            }
                        });
                    }
                }
            }

            // set mql
            this.mql.addListener(this.mqlHandler);
            this.mqlHandler();
        }

        private mqlHandler = (): void => {
            const itemsLength = this.items.length;

            if (itemsLength > 0) {
                for (let i = 0; i < itemsLength; i++) {
                    if (
                        this.links[i] &&
                        this.panels[i]
                    ) {
                        this.links[i].setAttribute('aria-expanded', 'false');
                        this.checkOpenPanelArray[i] = false;
                        this.panels[i].classList.remove(utillty.className.close);
                        this.panels[i].classList.remove(utillty.className.open);
                    }
                }

                if (this.overlay.dataset.show === 'true') {
                    this.overlay.dataset.show = 'false';
                }

                this.openPanelIndex = null;
            }
        };

        private openPanel(indexNum: number): void {
            if (!this.checkOpenPanelArray[indexNum]) {
                this.panels[indexNum].classList.remove(utillty.className.close);
                this.panels[indexNum].classList.add(utillty.className.open);
                this.links[indexNum].setAttribute('aria-expanded', 'true');
                this.checkOpenPanelArray[indexNum] = true;
                this.openPanelIndex = indexNum;

                if (this.overlay.dataset.show !== 'true') {
                    this.overlay.dataset.show = 'true';
                }
            }
        }

        private closePanel(indexNum: number | null): void {
            if (
                indexNum !== null &&
                this.openPanelIndex !== null &&
                this.checkOpenPanelArray[indexNum]
            ) {
                this.panels[indexNum].classList.remove(utillty.className.open);
                this.panels[indexNum].classList.add(utillty.className.close);
                this.links[indexNum].setAttribute('aria-expanded', 'false');
                this.checkOpenPanelArray[indexNum] = false;

                if (!this.checkOpenPanelArray.includes(true)) {
                    this.openPanelIndex = null;
                    this.overlay.dataset.show = 'false';
                }
            }
        }
    }

    const header = (document.querySelector('.header') as HTMLElement);

    if (header) {
        new DropDown(header); //eslint-disable-line
    }
};
