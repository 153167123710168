// FIXME: 構造から全部直す。
export default async () => {
  const mql = window.matchMedia('screen and (max-width: 835px)');
  const header = document.querySelector('.header') as HTMLElement;
  const link = document.querySelector('.header__itemLink') as HTMLButtonElement;
  const btn = document.querySelector('.header__menuBtn') as HTMLButtonElement;
  const panel = document.querySelectorAll('.header__itemPanel');
  const spPanel = document.querySelector('.header__contents') as HTMLElement;
  const overlay = document.querySelector('.header__overlay') as HTMLElement;

  if (mql.matches) {
    document.body.classList.remove('is-fixed');
    btn.setAttribute('aria-expanded', 'false');
    setTimeout(() => {
      spPanel.style.opacity = '0';
    }, 100);
  } else {
    panel.forEach((x) => x.classList.remove('is-open'));
    panel.forEach((x) => x.classList.add('is-close'));
    link.setAttribute('aria-expanded', 'false');
    overlay.dataset.show = 'false';
    setTimeout(() => {
      panel.forEach((x) => ((x as HTMLElement).style.opacity = '0'));
      link.setAttribute('aria-expanded', 'false');
    }, 100);
  }
};
