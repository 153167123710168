




















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import I18n from '@/i18n/index';
import { Linkset, Sitemap } from '@/repository/kontent/stores/sitemapStore';
import FooterItem from './FooterItem.vue';

@Component({
  components: {
    FooterItem,
  },
})
export default class FooterGroup extends Vue {
  @Prop(Object) linkData!: Sitemap;
  @Prop({ default: '' }) title!: string | Array<string>;
  private i18n = I18n;

  private isMouseDown = false;
  private get primaryTitle() {
    return Array.isArray(this.title) ? this.title[0] : this.title;
  }
  private get followingTitle() {
    return Array.isArray(this.title) ? this.title.slice(1) : [];
  }
  private get linkgroup() {
    return this.linkData.getLinkSet(this.primaryTitle) as Linkset;
  }
  private get toplinkItem() {
    return this.linkgroup?.toplink?.value[0];
  }
  private get linkList() {
    const links = this.linkgroup?.list?.value;
    if (!links) return [];
    const result = links
      .map((x) => {
        if (x.toplink?.value[0]?.system.type === 'anchor') {
          return x.list?.value;
        } else {
          return x;
        }
      })
      .flat();
    return [...Array.from(new Set(result))];
  }
  private getLinkList(key: string) {
    return this.linkData.getLinkSet(key)?.list?.value;
  }
  private get topLinkRouteName() {
    return this.toplinkItem?.routername.value;
  }
  private get externalUrl() {
    return this.linkgroup?.url?.value;
  }
}
