
import { ContentItem, Elements } from '@kentico/kontent-delivery';

/**
 * Generated by '@kentico/kontent-model-generator@3.2.0'
 * Tip: You can replace 'ContentItem' with another generated class to fully leverage strong typing.
 */
export class Home extends ContentItem {
    public mvBodytext1!: Elements.TextElement;
    public recruitSubheadline!: Elements.TextElement;
    public mvLeadcopy1!: Elements.TextElement;
    public recruitHeadline!: Elements.TextElement;
    public mvBodytext2!: Elements.TextElement;
    public newsSubheadline!: Elements.TextElement;
    public translationTranslatonSettings!: Elements.CustomElement;
    public mvLeadcopy2!: Elements.TextElement;
    public noticeLinkedItems!: Elements.LinkedItemsElement<ContentItem>;
    public serviceHeadline!: Elements.TextElement;
    public serviceBodytext!: Elements.TextElement;
    public seoMetadataExampleToIncludeInAnyTypeMetaDescription!: Elements.TextElement;
    public newsHeadline!: Elements.TextElement;
    public seoMetadataExampleToIncludeInAnyTypeMetaTitle!: Elements.TextElement;
    public recruitBodytext!: Elements.TextElement;
    public serviceSubheadline!: Elements.TextElement;
    constructor() {
        super({
            propertyResolver: ((elementName: string) => {
                if (elementName === 'mv_bodytext1') {
                    return 'mvBodytext1';
                }
                if (elementName === 'recruit_subheadline') {
                    return 'recruitSubheadline';
                }
                if (elementName === 'mv_leadcopy1') {
                    return 'mvLeadcopy1';
                }
                if (elementName === 'recruit_headline') {
                    return 'recruitHeadline';
                }
                if (elementName === 'mv_bodytext2') {
                    return 'mvBodytext2';
                }
                if (elementName === 'news_subheadline') {
                    return 'newsSubheadline';
                }
                if (elementName === 'translation__translaton_settings') {
                    return 'translationTranslatonSettings';
                }
                if (elementName === 'mv_leadcopy2') {
                    return 'mvLeadcopy2';
                }
                if (elementName === 'notice_linked_items') {
                    return 'noticeLinkedItems';
                }
                if (elementName === 'service_headline') {
                    return 'serviceHeadline';
                }
                if (elementName === 'service_bodytext') {
                    return 'serviceBodytext';
                }
                if (elementName === 'seo_metadata_example_to_include_in_any_type__meta_description') {
                    return 'seoMetadataExampleToIncludeInAnyTypeMetaDescription';
                }
                if (elementName === 'news_headline') {
                    return 'newsHeadline';
                }
                if (elementName === 'seo_metadata_example_to_include_in_any_type__meta_title') {
                    return 'seoMetadataExampleToIncludeInAnyTypeMetaTitle';
                }
                if (elementName === 'recruit_bodytext') {
                    return 'recruitBodytext';
                }
                if (elementName === 'service_subheadline') {
                    return 'serviceSubheadline';
                }
                return elementName;
            })
        });
    }
}
