import Vue, { PluginObject, DirectiveFunction } from 'vue';

const observerCallback = (
  entries: Array<IntersectionObserverEntry>,
  observerObj: IntersectionObserver,
): void => {
  entries.forEach((entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-show');
      observerObj.unobserve(entry.target);
    }
  });
};

const observer = new IntersectionObserver(observerCallback, {
  root: null,
  rootMargin: '0px',
  threshold: 0,
});

const f: DirectiveFunction = (el) => {
  el.classList.add('is-fadeIn');
  observer.observe(el);
};

export default {
  install: () => Vue.directive('fadein', f),
} as PluginObject<never>;
