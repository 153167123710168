import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import I18n, { Languages } from '@/i18n';

Vue.use(VueRouter);

// const News = () => import("@/components/News/News.vue");
// const News2020 = () => import("@/components/News/2020/2020.vue");
// const Company = () => import("@/components/Company/Company.vue");
// const History = () => import("@/components/Company/History/History.vue");
// const Inquiry = () => import("@/components/Inquiry/Inquiry.vue");
// const Recruit = () => import("@/components/Recruit/Recruit.vue");
// const Topmessage = () => import("@/components/Recruit/Topmessage/Topmessage.vue");
// const Services = () => import("@/components/Services/Services.vue");

// 旧サイト誘導
const redirectRules: Array<RouteConfig> = [
  { path: '/solutions/multiDRM', redirect: '/ja/service/multidrm' },
  { path: '/solutions/azure', redirect: '/ja/service/azure' },
  { path: '/solutions/machinelearning', redirect: '/ja/service/azureml' },
  { path: '/solutions/HoloLens', redirect: '/ja/service/hololens' },
  { path: '/solutions/kentico', redirect: '/ja/service' },
  { path: '/solutions/sitecore', redirect: '/ja/service/sitecore' },
  { path: '/solutions/AlterLock', redirect: '/ja/service' },
  { path: '/ja-JP/solutions/multiDRM', redirect: '/ja/service/multidrm' },
  { path: '/ja-JP/solutions/azure', redirect: '/ja/service/azure' },
  { path: '/ja-JP/solutions/machinelearning', redirect: '/ja/service/azureml' },
  { path: '/ja-JP/solutions/HoloLens', redirect: '/ja/service/hololens' },
  { path: '/ja-JP/solutions/kentico', redirect: '/ja/service' },
  { path: '/ja-JP/solutions/sitecore', redirect: '/ja/service/sitecore' },
  { path: '/ja-JP/solutions/AlterLock', redirect: '/ja/service' },
  { path: '/company', redirect: '/ja/company' },
  { path: '/company/leadership', redirect: '/ja/company/leadership' },
  { path: '/company/history', redirect: '/ja/company/history' },
  { path: '/company/vision', redirect: '/ja/company' },
  { path: '/solutions', redirect: '/ja/service' },
  { path: '/news', redirect: '/ja/news' },
  { path: '/inquiryMultiDRMKit', redirect: '/ja/service/multidrm' },
  // TODO enのリンクを設定
  { path: '/en/solutions/multiDRM', redirect: '/ja/service/multidrm' },
  { path: '/en/solutions/azure', redirect: '/ja/service/azure' },
  { path: '/en/solutions/machinelearning', redirect: '/ja/service/azureml' },
  { path: '/en/solutions/HoloLens', redirect: '/ja/service/hololens' },
  { path: '/en/solutions/kentico', redirect: '/ja/service' },
  { path: '/en/solutions/sitecore', redirect: '/ja/service/sitecore' },
  { path: '/en/solutions/AlterLock', redirect: '/ja/service' },
];

const routes: Array<RouteConfig> = [
  ...redirectRules,
  { path: '/', redirect: { name: 'Home' } },
  {
    path: '/:language/service',
    name: 'ServiceList',
    meta: [
      {
        language: 'ja',
        title: 'サービス | 株式会社ネクストスケープ',
        desc:
          'ネクストスケープでは Microsoft Azureを用いた運用の推進、SitecoreによるWebサイトの開発・導入支援、クラウド環境の導入支援、「Microsoft PlayReady」、「Google Widevine」、「FairPlay Streaming」など主要DRM全てに対応するコンテンツ配信サービスの開発など、多岐にわたるソリューションを提供しています。',
      },
      {
        language: 'en',
        title: 'Services | NEXTSCAPE Inc.',
        desc:
          'Nexscape provides a wide range of solutions, including promotion of operations using Microsoft Azure, support for website development and implementation using Sitecore, support for cloud environment implementation, and development of content distribution services that support all major DRMs, such as Microsoft PlayReady, Google Widevine, and FairPlay Streaming.',
      },
    ],
    component: () => import(/* webpackChunkName: "service" */ '@/views/service/Index.vue'),
  },
  {
    path: '/:language/service/:name',
    name: 'Service',
    meta: [
      {
        language: 'ja',
        title: 'サービス詳細 | 株式会社ネクストスケープ',
        desc:
          'ネクストスケープでは Microsoft Azureを用いた運用の推進、SitecoreによるWebサイトの開発・導入支援、クラウド環境の導入支援、「Microsoft PlayReady」、「Google Widevine」、「FairPlay Streaming」など主要DRM全てに対応するコンテンツ配信サービスの開発など、多岐にわたるソリューションを提供しています。',
      },
      {
        language: 'en',
        title: 'ServiceDetail | NEXTSCAPE Inc.',
        desc:
          'Nexscape provides a wide range of solutions, including promotion of operations using Microsoft Azure, support for website development and implementation using Sitecore, support for cloud environment implementation, and development of content distribution services that support all major DRMs, such as Microsoft PlayReady, Google Widevine, and FairPlay Streaming.',
      },
    ],
    component: () => import(/* webpackChunkName: "serviceDetail" */ '@/views/service/Detail.vue'),
  },
  {
    path: '/:language/news',
    name: 'NewsList',
    meta: [
      {
        language: 'ja',
        title: 'ニュース | 株式会社ネクストスケープ',
        desc: 'ネクストスケープのニュース一覧ページです。',
      },
      {
        language: 'en',
        title: 'News | NEXTSCAPE Inc.',
        desc: 'This is the news list page of Nexscape.',
      },
    ],
    component: () => import(/* webpackChunkName: "news" */ '@/views/news/Index.vue'),
  },
  {
    path: '/:language/news/:year',
    name: 'NewsListFilter',
    meta: [
      {
        language: 'ja',
        title: 'ニュース | 株式会社ネクストスケープ',
        desc: 'ネクストスケープのニュース一覧ページです。',
      },
      {
        language: 'en',
        title: 'News | NEXTSCAPE Inc.',
        desc: 'This is the news list page of Nexscape.',
      },
    ],
    component: () => import(/* webpackChunkName: "news" */ '@/views/news/Index.vue'),
  },
  {
    path: '/:language/news/prev/:id',
    name: 'NewsPreview',
    component: () => import(/* webpackChunkName: "newsArticle" */ '@/views/news/Article.vue'),
  },
  {
    path: '/:language/news/:year/:article',
    name: 'News',
    meta: [
      {
        language: 'ja',
        title: 'ニュース記事 | 株式会社ネクストスケープ',
        desc: 'ネクストスケープのニュースページです。',
      },
      {
        language: 'en',
        title: 'NewsArticle | NEXTSCAPE Inc.',
        desc: 'This is the news page of Nexscape.',
      },
    ],
    component: () => import(/* webpackChunkName: "newsArticle" */ '@/views/news/Article.vue'),
  },
  {
    path: '/:language/company/',
    name: 'Company',
    meta: [
      {
        language: 'ja',
        title: '企業情報 | 株式会社ネクストスケープ',
        desc: 'ネクストスケープの企業情報のページです。',
      },
      {
        language: 'en',
        title: 'Corporate Information | NEXTSCAPE Inc.',
        desc: 'This is the corporate information page for Nextscape.',
      },
    ],
    component: () => import(/* webpackChunkName: "company" */ '@/views/company/Index.vue'),
  },
  {
    path: '/:language/company/history/',
    name: 'History',
    meta: [
      {
        language: 'ja',
        title: '沿革 | 株式会社ネクストスケープ',
        desc: 'ネクストスケープの沿革についてのページです。',
      },
      {
        language: 'en',
        title: 'Corporate History | NEXTSCAPE Inc.',
        desc: 'This page is about the history of Nexscape.',
      },
    ],
    component: () => import(/* webpackChunkName: "companyHistory" */ '@/views/company/History.vue'),
  },
  {
    path: '/:language/company/leadership/',
    name: 'Leadership',
    meta: [
      {
        language: 'ja',
        title: '役員略歴 | 株式会社ネクストスケープ',
        desc: 'ネクストスケープの役員一覧のページです。',
      },
      {
        language: 'en',
        title: 'Leadership | NEXTSCAPE Inc.',
        desc: 'This is the list page of Nextscape executives.',
      },
    ],
    component: () => import(/* webpackChunkName: "companyLeadership" */ '@/views/company/Leadership.vue'),
  },
  {
    path: '/en/recruit/',
    redirect: '/en/',
  },
  {
    path: '/:language/recruit/',
    name: 'Recruit',
    meta: [
      {
        language: 'ja',
        title: '採用情報 | 株式会社ネクストスケープ',
        desc: 'ネクストスケープの採用情報のページです、お仕事内容を記載しています。',
      },
    ],
    component: () => import(/* webpackChunkName: "recruit" */ '@/views/recruit/Index.vue'),
  },
  {
    path: '/en/recruit/top-message/',
    redirect: '/en/',
  },
  {
    path: '/:language/recruit/top-message/',
    name: 'TopMessage',
    meta: [
      {
        language: 'ja',
        title: '社長メッセージ | 株式会社ネクストスケープ',
        desc: 'ネクストスケープの社長メッセージのページです。',
      },
    ],
    component: () => import(/* webpackChunkName: "recruitTopMessage" */ '@/views/recruit/Top-message.vue'),
  },
  {
    path: '/en/recruit/interview/',
    redirect: '/en/',
  },
  {
    path: '/:language/recruit/interview/',
    name: 'Interview',
    meta: [
      {
        language: 'ja',
        title: '社員インタビュー | 株式会社ネクストスケープ',
        desc: 'ネクストスケープの社員インタビューのページです。',
      },
    ],
    component: () => import(/* webpackChunkName: "recruitInterview" */ '@/views/recruit/Interview.vue'),
  },
  {
    path: '/en/recruit/interview/interview01/',
    redirect: '/en/',
  },
  {
    path: '/:language/recruit/interview/interview01/',
    name: 'Interview01',
    meta: [
      {
        language: 'ja',
        title: '社員インタビュー001：Webエンジニア | 株式会社ネクストスケープ',
        desc:
          'ネクストスケープの社員インタビューのページです。仕事の内容やネクストスケープに入社した理由等をご紹介します。',
      },
    ],
    component: () => import(/* webpackChunkName: "recruitInterview01" */ '@/views/recruit/Interview01.vue'),
  },
  {
    path: '/en/recruit/interview/interview02/',
    redirect: '/en/',
  },
  {
    path: '/:language/recruit/interview/interview02/',
    name: 'Interview02',
    meta: [
      {
        language: 'ja',
        title: '社員インタビュー002：エンジニア | 株式会社ネクストスケープ',
        desc:
          'ネクストスケープの社員インタビューのページです。仕事の内容やネクストスケープに入社した理由等をご紹介します。',
      },
    ],
    component: () => import(/* webpackChunkName: "recruitInterview02" */ '@/views/recruit/Interview02.vue'),
  },
  {
    path: '/en/recruit/interview/interview03/',
    redirect: '/en/',
  },
  {
    path: '/:language/recruit/interview/interview03/',
    name: 'Interview03',
    meta: [
      {
        language: 'ja',
        title: '社員インタビュー003：エンジニア | 株式会社ネクストスケープ',
        desc:
          'ネクストスケープの社員インタビューのページです。仕事の内容やネクストスケープに入社した理由等をご紹介します。',
      },
    ],
    component: () => import(/* webpackChunkName: "recruitInterview03" */ '@/views/recruit/Interview03.vue'),
  },
  {
    path: '/en/recruit/interview/interview04/',
    redirect: '/en/',
  },
  {
    path: '/:language/recruit/interview/interview04/',
    name: 'Interview04',
    meta: [
      {
        language: 'ja',
        title: '社員インタビュー004：営業SE | 株式会社ネクストスケープ',
        desc:
          'ネクストスケープの社員インタビューのページです。仕事の内容やネクストスケープに入社した理由等をご紹介します。',
      },
    ],
    component: () => import(/* webpackChunkName: "recruitInterview04" */ '@/views/recruit/Interview04.vue'),
  },
  {
    path: '/:language/inquiry/',
    component: () => import('@/views/inquiry/Index.vue'),
    children: [
      {
        path: '',
        name: 'Inquiry',
        meta: [
          {
            language: 'ja',
            title: 'お問い合わせ内容の入力 | 株式会社ネクストスケープ',
            desc:
              'ネクストスケープのお問い合わせページです。弊社事業およびサービスに関してのご質問・ご不明な点はお気軽に弊社までお問い合わせください。',
          },
          {
            language: 'en',
            title: 'Enter your inquiry details | NEXTSCAPE Inc.',
            desc:
              'This is the contact page of Nexscape. If you have any questions or concerns about our business or services, please feel free to contact us.',
          },
        ],
        component: () => import('@/views/inquiry/input.vue'),
      },
      {
        path: 'confirm',
        name: 'InquiryConfirm',
        meta: [
          {
            language: 'ja',
            title: 'お問い合わせ内容の確認 | 株式会社ネクストスケープ',
            desc:
              'ネクストスケープのお問い合わせページです。弊社事業およびサービスに関してのご質問・ご不明な点はお気軽に弊社までお問い合わせください。',
          },
          {
            language: 'en',
            title: 'Confirm your inquiry details | NEXTSCAPE Inc.',
            desc:
              'This is the contact page of Nexscape. If you have any questions or concerns about our business or services, please feel free to contact us.',
          },
        ],
        component: () => import('@/views/inquiry/confirm.vue'),
      },
      {
        path: 'thanks',
        name: 'InquiryThanks',
        meta: [
          {
            language: 'ja',
            title: 'お問い合わせ完了 | 株式会社ネクストスケープ',
            desc:
              'ネクストスケープのお問い合わせページです。弊社事業およびサービスに関してのご質問・ご不明な点はお気軽に弊社までお問い合わせください。',
          },
          {
            language: 'en',
            title: 'Inquiry completed | NEXTSCAPE Inc.',
            desc:
              'This is the contact page of Nexscape. If you have any questions or concerns about our business or services, please feel free to contact us.',
          },
        ],
        component: () => import('@/views/inquiry/thanks.vue'),
      },
    ],
  },
  {
    path: '/:language/privacy-policy/',
    name: 'PrivacyPolicy',
    meta: [
      {
        language: 'ja',
        title: 'プライバシーポリシー | 株式会社ネクストスケープ',
        desc: 'ネクストスケープのプライバシーポリシーのページです。',
      },
      {
        language: 'en',
        title: 'Pricacy Policy | NEXTSCAPE Inc.',
        desc: 'This is the privacy policy page of Nexscape.',
      },
    ],
    component: () => import(/* webpackChunkName: "privacy" */ '@/views/privacy-policy/Index.vue'),
  },
  {
    path: '/:language/quality-policy/',
    name: 'QualityPolicy',
    meta: [
      {
        language: 'ja',
        title: '品質方針 | 株式会社ネクストスケープ',
        desc: 'ネクストスケープの品質方針のページです。',
      },
      {
        language: 'en',
        title: 'Quality Policy | NEXTSCAPE Inc.',
        desc: 'This is the Quality policy page of Nexscape.',
      },
    ],
    component: () => import(/* webpackChunkName: "privacy" */ '@/views/quality-policy/Index.vue'),
  },
  {
    path: '/:language/security-policy/',
    name: 'SecurityPolicy',
    meta: [
      {
        language: 'ja',
        title: '情報セキュリティポリシー | 株式会社ネクストスケープ',
        desc: 'ネクストスケープの情報セキュリティ方針のページです。',
      },
      {
        language: 'en',
        title: 'Security Policy | NEXTSCAPE Inc.',
        desc: "NextScape's information security policy page.",
      },
    ],
    component: () => import(/* webpackChunkName: "security" */ '@/views/security-policy/Index.vue'),
  },
  {
    path: '/:language/',
    name: 'Home',
    meta: [
      {
        language: 'ja',
        title: '株式会社ネクストスケープ',
        desc:
          'ネクストスケープは、お客様の新規事業開拓および既存事業発展に最適なITソリューションを、企画提案・開発・保守の全ての工程において提供しています。',
      },
      {
        language: 'en',
        title: 'NEXTSCAPE Inc.',
        desc:
          "Nexscape provides optimal IT solutions for our clients' new business development and existing business growth in all stages of planning, proposal, development and maintenance.",
      },
    ],
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Index.vue'),
  },
  {
    path: '/:language/*',
    name: 'Default',
    redirect: { name: 'Home' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!savedPosition) return { x: 0, y: 0 };
    return savedPosition;
  },
});

function configureLanguage(route: Route) {
  if (route.fullPath.startsWith('/ja/') || route.fullPath == '/ja' || route.params.language === 'ja') {
    I18n.setLanguage('ja');
    return;
  }
  if (route.fullPath.startsWith('/en/') || route.fullPath == '/en' || route.params.language === 'en') {
    I18n.setLanguage('en');
    return;
  }

  router.replace({ path: `ja${route.fullPath}` });
}

const recruitePaths = new Set<string>([
  '/recruit',
  '/ja/recruit',
  '/ja/recruit/top-message',
  '/ja/recruit/interview',
  '/ja/recruit/interview/interview01',
  '/ja/recruit/interview/interview02',
  '/ja/recruit/interview/interview03',
  '/ja/recruit/interview/interview04',
]);

router.beforeEach((to, from, next) => {
  if (recruitePaths.has(to.path) || recruitePaths.has(to.path.slice(0, -1)))
    window.location.href = `https://${process.env.VUE_APP_SUB_DOMAIN}.nextscape.net/recruit/`;
  configureLanguage(to);
  next();
});

export default router;

export function replaceLanguageCode(l: Languages) {
  if (router.currentRoute.params.language === l) return;
  if (!router.currentRoute.name) return;

  const name = router.currentRoute.name;
  const params = router.currentRoute.params;
  params.language = l;
  const query = router.currentRoute.query;
  router.push({ name, params, query });
}

export type routeObj = {
  path: string;
  name: string;
  meta: metaObj;
};

export type metaObj = {
  title: string;
  desc: string;
};
