import utility from './utility';

export default (): void => {
    const TabList = (() => {
        let instanceCount = 0;

        return class {
            private tabs: NodeListOf<HTMLElement>;
            private panels: NodeListOf<HTMLElement>;
            private showPanelIndex: number;

            constructor(root: HTMLElement) {
                this.tabs = root.querySelectorAll('.tabList__listTab');
                this.panels = root.querySelectorAll('.tabList__contentsItem');
                this.showPanelIndex = 0;

                const tabsLength = this.tabs.length;
                const panelsLength = this.panels.length;

                if (
                    tabsLength === 0 ||
                    panelsLength === 0 ||
                    tabsLength !== panelsLength
                ) {
                    throw new Error('Failed to generate class TabList.');
                }

                for (let i = 0; i < tabsLength; i++) {
                    const tab = this.tabs[i];

                    tab.id = `tabList-${instanceCount}-${i}`;
                    tab.tabIndex = -1;
                    tab.setAttribute('aria-selected', 'false');
                    tab.dataset.tabNum = `${i}`;

                    tab.addEventListener('click', this.clickHandler);
                    tab.addEventListener('keydown', this.keydownHandler);
                }

                for (let i = 0; i < panelsLength; i++) {
                    const panel = this.panels[i];

                    panel.id = `tabPanel-${instanceCount}-${i}`;
                    panel.setAttribute('aria-labelledby', this.tabs[i].id);
                    panel.tabIndex = 0;

                    // パネルに付与したid属性の値で、対応するタブにaria-controlsを付与
                    this.tabs[i].setAttribute('aria-controls', panel.id);
                }

                // 初期表示用に指定のパネルを表示する。
                this.tabs[this.showPanelIndex].setAttribute('aria-selected', 'true');
                this.tabs[this.showPanelIndex].tabIndex = 0;
                this.panels[this.showPanelIndex].classList.add(utility.className.show);

                // カウント更新
                instanceCount++;
            }

            /**
             * checkTargetDataset
             *
             * @param {HTMLElement} checkTarget : datasetの判定対象になる要素
             *
             * 引数の要素のdata-tab-numを判定＆number型にしてreturnする。
             *
             */
            private checkTargetDataset = (checkTarget: HTMLElement): number => {
                const targetDataset = checkTarget.dataset.tabNum;

                if (targetDataset) {
                    return parseInt(targetDataset, 10);
                }

                throw new Error('targetDataset is undefined');
            }

            /**
             * clickHandler
             *
             * 表示しているパネルのタブ以外のタブをクリックした際表示切り替え
             *
             */
            private clickHandler = (e: MouseEvent): void => {
                const targetDatasetNum = this.checkTargetDataset(e.target as HTMLElement);

                // 今表示しているパネル以外のパネルが対象だったらshowPanelを実行する。
                if (targetDatasetNum !== this.showPanelIndex) {
                    this.showPanel(targetDatasetNum);
                }
            }

            /**
             * keydownHandler
             *
             * 左右の方向キーでタブでの表示切り替え（以下判定条件）
             *
             * 右にあるタブを表示
             * - キーボードで右方向キーを押下したか
             * - 対象のタブが持つデータセットの値がタブの全体数以下か（＝最後のタブは右にタブが無い為）
             *
             * 左にあるタブを表示
             * - キーボードで左方向キーを押下したか
             * - 対象のタブが持つデータセットの値が0ではないか（＝最初のタブは左にタブが無い為）
             *
             */
            private keydownHandler = (e: KeyboardEvent): void => {
                const targetDatasetNum = this.checkTargetDataset(e.target as HTMLElement);

                if (
                    e.key === 'ArrowRight' &&
                    targetDatasetNum < (this.tabs.length - 1)
                ) {
                    this.showPanel(targetDatasetNum + 1); // 右にあるタブを表示
                } else if (
                    e.key === 'ArrowLeft' &&
                    targetDatasetNum !== 0
                ) {
                    this.showPanel(targetDatasetNum - 1); // 左にあるタブを表示
                }
            }

            /**
             * showPanel
             *
             * @param {number} showIndex : 表示するパネルのindex番号
             *
             * パネルの表示切り替え処理
             *
             */
            private showPanel = (showIndex: number): void => {
                this.hiddenPanel();
                this.visiblePanel(showIndex);
            }

            /**
             *
             * visiblePanel
             *
             * @param {number} targetIndexNum : 処理対象になるパネルのindex番号
             *
             * 表示するタブとパネルのindex（targetIndexNum）を使用して下記の表示処理を実行
             * - 表示タブのaria-selectedをtrueにする
             * - 表示タブのtabIndexを0にする
             * - 表示パネルの表示class（is-show）を付与
             *
             */
            private visiblePanel = (targetIndexNum: number): void => {
                this.tabs[targetIndexNum].setAttribute('aria-selected', 'true');
                this.tabs[targetIndexNum].tabIndex = 0;
                this.panels[targetIndexNum].classList.add(utility.className.show);

                // 表示するタブにフォーカス移動
                this.tabs[targetIndexNum].focus();

                this.showPanelIndex = targetIndexNum;
            }

            /*
             *
             * hiddenPanel
             *
             * 表示しているタブとパネルのindex（this.showPanelIndex）を使用して下記の非表示処理を実行
             * - 表示タブのaria-selectedをfalseにする
             * - 表示タブのtabIndexを-1にする
             * - 表示パネルの表示class（is-show）を削除
             *
             */
            private hiddenPanel = (): void => {
                this.tabs[this.showPanelIndex].setAttribute('aria-selected', 'false');
                this.tabs[this.showPanelIndex].tabIndex = -1;
                this.panels[this.showPanelIndex].classList.remove(utility.className.show);
            }
        };
    })();

    const roots = document.querySelectorAll('.js-tabList');
    const rootLength = roots.length;

    if (rootLength > 0) {
        for (let i = 0; i < rootLength; i++) {
            new TabList(roots[i] as HTMLElement); // eslint-disable-line
        }
    }
};
