import utillty from './utility';

export default (): void => {
    class HamburgerMenu {
        private header: HTMLElement;
        private btn: HTMLButtonElement;
        private btnInner: HTMLSpanElement;
        private panel: HTMLElement;
        private panelInner: HTMLElement;
        private closeBtn: HTMLButtonElement;
        private body: HTMLElement;
        private overlay: HTMLElement;
        private mql: MediaQueryList;
        private isTransition: boolean;
        private userAgent: string;

        constructor() {
            this.header = document.querySelector('.header') as HTMLElement;
            this.btn = document.querySelector('.header__menuBtn') as HTMLButtonElement;
            this.btnInner = this.btn.querySelector('span') as HTMLSpanElement;
            this.panel = document.querySelector('.header__contents') as HTMLElement;
            this.panelInner = document.querySelector('.header__contentsInner') as HTMLElement;
            this.closeBtn = document.querySelector('.header__closeBtn button') as HTMLButtonElement;
            this.body = document.body;
            this.overlay = document.querySelector('.header__overlay') as HTMLElement;
            this.mql = utillty.mql;
            this.isTransition = false;
            this.userAgent = navigator.userAgent;

            // set attribute
            this.panel.id = 'hamburger-panel';
            this.btn.setAttribute('aria-controls', this.panel.id);
            this.btn.setAttribute('aria-expanded', 'false');

            // set mql
            this.mql.addListener(this.mqlHandler);
            this.mqlHandler();

            // set event
            this.btn.addEventListener('click', this.btnClickHandler);
            this.closeBtn.addEventListener('click', this.closePanel);
            this.closeBtn.addEventListener('keydown', this.closeBtnKeydownHandler);
            this.panel.addEventListener('transitionend', this.transitionendHandler);

            // パネルが開いてる時のフォーカス制御
            this.btn.addEventListener('keydown', e => {
                if (
                    e.shiftKey &&
                    e.key === 'Tab' &&
                    this.header.classList.contains(utillty.className.open)
                ) {
                    e.preventDefault();
                    this.closeBtn.focus();
                }
            });

            // SP端末でlocationバーが出てくる際などにパネルの高さを変更
            if (
                (this.mql.matches) &&
                (this.userAgent.indexOf('iPhone') >= 0 ||
                this.userAgent.indexOf('iPad') >= 0 ||
                this.userAgent.indexOf('Android') >= 0)
            ) {
                this.panelInner.style.height = `${window.innerHeight - 60}px`;

                window.addEventListener('resize', () => {
                    if (!this.mql.matches) {
                        return;
                    }

                    if (this.header.classList.contains(utillty.className.open)) {
                        this.panelInner.style.height = `${window.innerHeight - 60}px`;
                    }
                });
            }
        }

        private mqlHandler = (): void => {
            this.header.classList.remove(utillty.className.open);
            this.body.classList.remove(utillty.className.fixed);
            this.btn.setAttribute('aria-expanded', 'false');
            this.btnInner.textContent = 'menu';
            this.panel.style.opacity = '';
            this.panelInner.style.height = '';
        };

        private btnClickHandler = (): void => {
            if (this.isTransition) {
                return;
            }

            this.isTransition = true;

            if (this.header.classList.contains(utillty.className.open)) {
                this.closePanel();
            } else {
                this.openPanel();
            }
        }

        private closeBtnKeydownHandler = (e: KeyboardEvent): void => {
            if (
                e.shiftKey &&
                e.key === 'Tab'
            ) {
                return;
            }

            if (e.key === 'Tab') {
                e.preventDefault();
                this.btn.focus();
            }
        }

        private transitionendHandler = (e: TransitionEvent): void => {
            if (
                this.mql.matches &&
                e.propertyName === 'opacity'
            ) {
                if (this.btn.getAttribute('aria-expanded') === 'true') {
                    this.body.classList.add(utillty.className.fixed);
                    this.overlay.dataset.show = 'true';
                    this.btnInner.textContent = 'close';
                } else {
                    this.header.classList.remove(utillty.className.open);
                    this.overlay.dataset.show = 'false';
                    this.btnInner.textContent = 'menu';
                    this.btn.focus();

                    this.panel.querySelectorAll(`.${utillty.className.open}`).forEach(item => {
                        item.classList.remove(utillty.className.open);
                        (item.querySelector('.header__itemLink') as HTMLElement).setAttribute('aria-expanded', 'false');
                        (item.querySelector('.header__itemPanel') as HTMLElement).style.height = '';
                    });
                }

                this.isTransition = false;
            }
        }

        private openPanel = (): void => {
            this.header.classList.add(utillty.className.open);
            this.btn.setAttribute('aria-expanded', 'true');

            setTimeout(() => {
                this.panel.style.opacity = '1';
                this.panelInner.style.height = `${window.innerHeight - 60}px`;
            }, 100);
        }

        private closePanel = (): void => {
            this.body.classList.remove(utillty.className.fixed);
            this.btn.setAttribute('aria-expanded', 'false');

            setTimeout(() => {
                this.panel.style.opacity = '0';
            }, 100);
        }
    }

    new HamburgerMenu(); //eslint-disable-line
};
