














































import { Component, Prop, Vue } from 'vue-property-decorator';
import I18n from '@/i18n/index';
import closeHeaderMenu from '@/_ns_modules/src/ts/closeHeaderMenu';
import { Service } from '@/repository/kontent/stores/serviceStore';
import { Link } from '@/repository/kontent/stores/sitemapStore';

@Component
export default class HeaderItem extends Vue {
  @Prop(Object) linkItem!: Link | Service;
  @Prop(String) top!: string;
  private i18n = I18n;
  private closeHeaderMenu = closeHeaderMenu;

  private get routerName() {
    return this.linkItem?.routername?.value;
  }
  private get externalUrl() {
    return this.linkItem?.externalUrl?.value;
  }
  private get title() {
    return this.linkItem?.title?.value;
  }
  private get subTitle() {
    return this.linkItem?.subtitle?.value ?? '';
  }
}
