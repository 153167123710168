import Vue, { PluginObject } from 'vue';

export default {
  install: () =>
    Vue.directive('focusout', {
      bind: function (el: any) {
        el.addEventListener('click', () => {
          setTimeout(() => {
            el.blur();
          }, 100);
        });
      },
    }),
} as PluginObject<never>;
