export default {
    className: {
        open: 'is-open',
        close: 'is-close',
        fixed: 'is-fixed',
        show: 'is-show',
        hidden: 'is-hidden',
        current: 'is-current'
    },
    contentWidth: 1320,
    mql: window.matchMedia('screen and (max-width: 835px)')
};
